import styled from 'styled-components';
import { device } from '@styles/media';

const HeaderColumn = styled.div`
  width: 320px;
  height: 1180;
  margin-top: 29px;
  position: relative;

  @media ${device.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 48px;
    margin-top: 0px;
  }
`;

const PlanInfoDiv = styled.div`
  height: 330px;

  @media ${device.mobile} {
    height: auto;
  }
`;

const HeaderTxt = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  height: 26px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

const HeaderSubTxt = styled.p`
  color: ${({ theme }) => theme.tokens.gray[1300]};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  width: 250px;
  height: 42px;
  margin: 4px 0 78px 0;
  text-align: left;

  @media ${device.mobile} {
    margin-bottom: 0px;
  }
`;

const HeaderTxtDesktop = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  height: 26px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;

  @media ${device.mobile} {
    display: none;
  }
`;

const BlankSpaceFreeHeader = styled.div`
  height: 200px;

  @media ${device.mobile} {
    display: none;
  }
`;

const FeatureTxt = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-top: 94px;
  margin-bottom: 20px;
  text-align: left;

  @media ${device.mobile} {
    display: none;
  }
`;

const FeatureDivDesktop = styled.div`
  height: auto;

  @media ${device.mobile} {
    display: none;
  }
`;

export default {
  HeaderColumn,
  PlanInfoDiv,
  HeaderTxt,
  HeaderSubTxt,
  HeaderTxtDesktop,
  BlankSpaceFreeHeader,
  FeatureTxt,
  FeatureDivDesktop,
};
