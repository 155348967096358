import { find } from 'lodash';
import { useEffect, useState } from 'react';

import { PlanModel } from '@remote-data/load-offers/types';
import ColumnCorporate from '@screens/landing-page-default/components/plans/components/column-corporate/index';
import ColumnFree from '@screens/landing-page-default/components/plans/components/column-free/index';
import ColumnHeader from '@screens/landing-page-default/components/plans/components/column-header/index';
import ColumnPremium from '@screens/landing-page-default/components/plans/components/column-premium/index';
import Styles from '@screens/landing-page-default/components/plans/style';
import { PlansProps } from '@screens/landing-page-default/components/plans/types';
import General from '@styles/general';

export default function Plans({ offersData }: PlansProps) {
  const [cash, setCash] = useState<PlanModel>();
  const [installment, setInstallment] = useState<PlanModel>();

  useEffect(() => {
    if (offersData && offersData.planList.length) {
      setCash(find(offersData.planList, { splittable: false }));
      setInstallment(find(offersData.planList, { splittable: true }));
    }
  }, [offersData]);

  return cash && Object.keys(cash) ? (
    <General.Wrapper padding data-testid="plans">
      <General.SubTitle width="500px">Aproveite nossas ofertas e tenha o Whitebook junto de você!</General.SubTitle>
      <Styles.PlanContainer>
        <ColumnHeader />
        <ColumnFree />
        <ColumnPremium offersData={offersData} cash={cash} installment={installment} />
        <ColumnCorporate />
      </Styles.PlanContainer>
    </General.Wrapper>
  ) : null;
}
