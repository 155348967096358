import Styles from '@screens/landing-page-default/components/plans/components/column-header/style';
import PlanFeatureTableHeader from '@screens/landing-page-default/components/plans/components/feature-table/header/index';

export default function ColumnHeader() {
  return (
    <Styles.HeaderColumn>
      <Styles.PlanInfoDiv>
        <Styles.HeaderTxt>Planos disponíveis</Styles.HeaderTxt>
        <Styles.HeaderSubTxt>Selecione o plano que se encaixa melhor com as suas necessidades.</Styles.HeaderSubTxt>
        <Styles.HeaderTxtDesktop>Preços</Styles.HeaderTxtDesktop>
        <Styles.BlankSpaceFreeHeader />
      </Styles.PlanInfoDiv>
      <Styles.FeatureTxt>Características do plano</Styles.FeatureTxt>
      <Styles.FeatureDivDesktop>
        <PlanFeatureTableHeader
          featureRow1="Acesso"
          featureRow2="Medicamentos"
          featureRow3="Código CID, TUSS e SUS"
          featureRow4="Fluxogramas interativos"
          featureRow5="Prescrições"
          featureHighlight1="Condutas clínicas"
          featureHighlight2="Calculadoras e escores"
        />
      </Styles.FeatureDivDesktop>
    </Styles.HeaderColumn>
  );
}
