import styled from 'styled-components';
import { device } from '@styles/media';

const ColumnRecommendedPlan = styled.div`
  width: 322px;
  height: 1180;
  padding: 5px 10px;
  position: relative;
  border: 2px solid ${({ theme }) => theme.tokens.orange[500]};
  border-radius: 24px;

  @media ${device.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 20px 25px;
  }
`;

const DivTag = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Tag = styled.span`
  position: absolute;
  top: -20px;
  background: ${({ theme }) => theme.tokens.orange[500]};
  border-radius: 15px;
  color: ${({ theme }) => theme.tokens.gray[100]};
  text-align: center;
  width: 140px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 12px;

  @media ${device.mobile} {
  }
`;

const PlanInfoDiv = styled.div`
  height: 330px;

  @media ${device.mobile} {
    height: auto;
  }
`;

const Type = styled.h3`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.blue[800]};
  margin: 0 0 20px 0;
  text-align: center;
`;

const Price = styled.h4`
  display: block;
  font-size: 19px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[1300]};
  margin: 90px 0 0 0;
  text-align: center;

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px 0;
  }
`;

const Installment = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[1300]};
  margin: 8px 0 40px 0;
  text-align: center;

  @media ${device.mobile} {
    font-size: 12px;
    line-height: 15px;
    margin: 4px 0 40px 0;
  }
`;

const PriceOneTime = styled.span`
  color: ${({ theme }) => theme.tokens.orange[500]};
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  display: block;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

const PriceOneTimeHighlight = styled.span`
  color: ${({ theme }) => theme.tokens.orange[500]};
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  display: block;
  margin-bottom: 80px;

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 40px;
  }
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 63px;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`;

const FeatureDivDesktop = styled.div`
  height: auto;

  @media ${device.mobile} {
    display: none;
  }
`;

const BlankSpaceFeature = styled.div`
  height: 1px;

  @media ${device.mobile} {
    display: none;
  }
`;

const BtnMaisDetalhes = styled.button`
  display: none;

  @media ${device.mobile} {
    display: block;
    color: ${({ theme }) => theme.tokens.blue[800]};
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    margin: 33px auto 10px auto;

    &:focus {
      text-decoration: underline;
    }
  }
`;

const BtnReduzir = styled.button`
  display: none;

  @media ${device.mobile} {
    display: block;
    color: ${({ theme }) => theme.tokens.blue[800]};
    background: transparent;
    border: none;
    margin: 33px auto 10px auto;
    font-size: 14px;

    &:focus {
      text-decoration: underline;
    }
  }
`;

const FeatureDivMobile = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    height: auto;
  }
`;

const GridMobile = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
`;

const GridItem = styled.div`
  padding: 1px;
`;

const FeatureTxtPlanColumn = styled.h4`
  font-size: 18px;
  margin-top: 48px;
  margin-bottom: 0;
  text-align: center;
  visibility: hidden;

  @media ${device.mobile} {
    visibility: visible;
    color: ${({ theme }) => theme.tokens.gray[1900]};
    font-weight: 500;
    letter-spacing: -0.02em;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }
`;

const Div = styled.div``;

export default {
  ColumnRecommendedPlan,
  DivTag,
  Tag,
  PlanInfoDiv,
  Type,
  Price,
  Installment,
  PriceOneTime,
  PriceOneTimeHighlight,
  DivBtn,
  FeatureDivDesktop,
  BlankSpaceFeature,
  BtnMaisDetalhes,
  BtnReduzir,
  FeatureDivMobile,
  GridMobile,
  GridItem,
  FeatureTxtPlanColumn,
  Div,
};
