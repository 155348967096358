import styled from 'styled-components';
import { device } from '@styles/media';

const Column = styled.div`
  height: auto;
`;

const HorizontalLine = styled.hr`
  background-color: ${({ theme }) => theme.tokens.gray[700]};
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.tokens.gray[700]};
  margin-left: auto;
  margin-right: auto;

  @media ${device.mobile} {
    margin-bottom: 1px;
    margin-top: 1px;
  }
`;

const Features = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1300]};
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: left;

  @media ${device.mobile} {
    font-size: 14px;
    text-align: left;
    margin: 16px auto 16px auto;
  }
`;

const FeaturesHighlight = styled.h4`
  color: ${({ theme }) => theme.tokens.blue[800]};
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: left;

  @media ${device.mobile} {
    font-size: 14px;
    text-align: left;
    margin: 16px auto 21px auto;
  }
`;

export default {
  Column,
  HorizontalLine,
  Features,
  FeaturesHighlight,
};
