import { HttpResponse } from '@remote-data/http-client/types';
import { OffersFromAPI, OffersModel, PlanModel } from '@remote-data/load-offers/types';

const responseFromPlanModel = (planList: PlanModel[]): PlanModel[] =>
  planList.map((plan) => ({
    id: plan.id,
    storeId: plan.storeId,
    title: plan.title.match(/Anual/gi).toString(),
    subtitle: plan.subtitle,
    price: plan.price,
    period: plan.period,
    discountPercentage: Math.round(plan.discountPercentage),
    discountCouponCode: plan.discountCouponCode,
    priority: plan.priority,
    gateway: plan.gateway,
    splittable: plan.splittable,
    acceptsCoupon: plan.acceptsCoupon,
  }));

export const responseFromOffersModel = ({
  statusCode,
  body: { highlightedText, plans },
}: HttpResponse<OffersFromAPI>): HttpResponse<OffersModel> => ({
  statusCode,
  body: {
    highlightedText,
    buttonLabel: plans.buttonLabel,
    basePrice: {
      annually: plans.basePrice.annually,
      monthly: plans.basePrice.monthly,
    },
    benefits: plans.benefits,
    planList: responseFromPlanModel(plans.list),
  },
});
